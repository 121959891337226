<template>
  <div>
    <!-- 卡片式表单 -->
    <div class="card">
      <!-- 表单项 -->
      <div class="card-item">
        <div class="label">活动评价</div>
        <textarea v-model="formData.content" placeholder="活动评价内容" cols="30" rows="10"></textarea>
        <!-- 图片块 -->
        <div class="image-block flex-row-default-center">
          <div class="show-image" v-for="(item, index) in formData.pictures" :key="index">
            <img class="image" :src="item" />
            <!-- 移除图片的按钮 -->
            <div class="remove-image flex-center-center" @click="handleDeleteImage(index)">×</div>
          </div>
          <!-- 选择图片上传按钮 -->
          <div v-if="formData.pictures.length < 4" class="add-image flex-center-center" @click="handleSelectImage">+</div>
        </div>
      </div>
    </div>

    <!-- 添加按钮 -->
    <div class="button-one" @click="handleAdd">添加评价</div>

    <!-- 上传图片input -->
    <input v-show="false" ref="file" type="file" accept="image/*" @change="handleUploadImage" />
  </div>
</template>

<script>
import { Toast } from 'mint-ui'
import { uploadToOSS } from '@/request/upload'

export default {
  name: 'ActivityEvaluation',

  data () {
    return {
      activityID: '',
      formData: {
        content: '',
        pictures: []
      }
    }
  },

  created () {
    this.activityID = this.$route.query.id
  },

  methods: {
    // 添加评价
    handleAdd () {
      if (this.formData.content === '') {
        Toast('评价内容不能为空')
        return
      }
      this.api.addActivityEvaluation({ id: this.activityID, ...this.formData }).then(res => {
        Toast(res.msg)
        this.$router.replace('/activity-detail')
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.handleAdd() })
      })
    },
    // 选择图片
    handleSelectImage () {
      this.$refs.file.click()
    },
    // 删除图片
    handleDeleteImage (index) {
      this.formData.pictures.splice(index, 1)
    },
    // 上传图片
    async handleUploadImage () {
      if (this.$refs.file.files[0]) {
        const file = this.$refs.file.files[0]
        const res = await this.api.getAliOSSToken()
        await uploadToOSS(res.data, file).then(res => {
          this.formData.pictures.push(res)
        }).catch(() => {
          Toast('上传失败')
        })
      }
    }
  }
}
</script>

<style scoped>
.card {
  margin-top: 0;
}
</style>
